import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

var firebaseConfig = {
  apiKey: "AIzaSyBN6hF7VbStv2RCEWvqlNOM1pMlUoEmY40",
  authDomain: "video-a535e.firebaseapp.com",
  databaseURL: "https://video-a535e.firebaseio.com",
  projectId: "video-a535e",
  storageBucket: "video-a535e.appspot.com",
  messagingSenderId: "260351304747",
  appId: "1:260351304747:web:9426b79e349e7cb227b66e"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;