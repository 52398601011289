import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createProject } from '../../store/actions/projectActions'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/app'
import axios from 'axios'
require('firebase/functions')

class CreateProject extends Component {
  state = {
    title: '',
    content: '',
    fileName: '',
    loading: {
      completed: 0,
      loadingWidth: {
        width: '0%'
      }
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.createProject(this.state)
    this.props.history.push('/');
  }
  uploadVideo = (e) => {
    var file = e.target.files[0];
    var video = e.target.files[0].name;
    var filetype = e.target.files[0].type;
    var getPost = firebase.functions().httpsCallable('getS3SignedUrlUpload');
    var params = {
      key: video,
      contentType: filetype,
      bucket: 'zanevideodemo'
    }
    getPost(params).then(result => {  // S3 pre-signed URL
      console.log(result);
      const formData = new FormData();
      formData.append('file',file)
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-amz-acl': 'public-read',
          'x-amz-server-side-encryption': 'AES256'
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({
            loading: {
              loadingWidth: {
                width: percentCompleted + '%'
              },
              completed: percentCompleted
            }
          })
          // console.log(progressEvent.loaded)
        }
      };   // Run a HTTP PUT request to the pre-signed URL
      return axios.put(result.data, formData, options)
        .then(res => {
          console.log(res);
          this.setState({
            fileName: video
          });
        }).catch(err => {
          console.log(err);
        })
    })
  }
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />

    return (
      <div className="container">
        <div className="card">
          <div className="card-content">
            <form onSubmit={this.handleSubmit} className="white">
              <h5 className="grey-text text-darken-3">New Project</h5>
              <div className="file-field input-field">
                <div className="btn">
                  <span>Upload Video</span>
                  <input type="file" id="video" onChange={this.uploadVideo} />
                </div>
                <div className="file-path-wrapper">
                  <input className="file-path validate" type="text" />
                </div>
                <div className="progress">
                  <div className="determinate" style={this.state.loading.loadingWidth}></div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="title">Title</label>
                <input type="text" id="title" onChange={this.handleChange} />
              </div>
              <div className="input-field">
                <label htmlFor="content">Content</label>
                <textarea className="materialize-textarea" id="content" onChange={this.handleChange}></textarea>
              </div>
              <div className="input-field">
                <button className="btn pink lighten-1 zdepth-0">Create</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (project) => dispatch(createProject(project))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)
